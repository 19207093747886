*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* responsive application configuration */
body {
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 100%;
  }
@media (max-width: 568px) {
    #root{
        overflow: hidden;
        padding:0;
        margin:0;
        width: 100%;
    }
    .hero-row {
        height: 250px;
    }
}
/* ---------------------------- */


/* display flex custom classes */

.cursorPointer{
    cursor: pointer;
}
.center1 {
    display: flex;
    justify-content: center;
}

.sideByside {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.start {
    display: flex;
    justify-content: start;
    align-items: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.end {
    display: flex;
    justify-content: end;
    align-items: center;
}

/* display flex ends here.................. */

.black {
    background-color: black;
}

/* text css starts here............. */

.textBold {
    font-weight: bolder;
    color: black;
}

.whiteBold {
    font-weight: bolder;
    color: white;
}

.themeBold {
    font-weight: bolder;
    color: #ed03be;
}

.letterSpacing {
    letter-spacing: 3px;
}

/* text css ends here............... */

.onTop-btn {
    height: 70px;
    width: 70px;
    border-radius: 50px;
    border: none;
    background-color: #ed03be;
    /* background-color: rgb(91, 109, 228); */
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.cursor {
    cursor: pointer !important;
}

.onTop-btn:hover {
    background-color: rgb(91, 109, 228);
    color: white;
    font-size: 20px;
    border: none;
}

.onTop-btn-container {
    position: fixed;
    height: 70px;
    width: 70px;
    border-radius: 50px;
    left: 95%;
    top: 90%;
    z-index: 1;
}

@media (max-width: 568px) {
    .onTop-btn {
        height: 50px;
        width: 50px;
        border-radius: 25px;
        font-size: 15px;
    }

    .onTop-btn-container {
        left: 90%;
    }
}


.custom-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: grid;
    place-items: center;
}

.custom-icon2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    display: grid;
    place-items: center;
}

.imgStyle {
    height: 100%;
    width: 100%;
}

.label {
    background-color: rgba(103, 103, 225, 0.1);
    color: rgba(103, 103, 225, 1);
    width: 20%;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
}

@media (max-width: 568px) {
    .label {
        width: 40%;
        margin-top: 20px;
    }
}

.centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hrtagline {
    width: 25%;
    background-color: rgb(85, 85, 223);
    height: 3px;
}

.equal-height-col {
    display: flex;
    flex-wrap: wrap;
}

.sectionHeight {
    margin-top: 100px;
}


/* Navbar stars here............... */

.sticky {
    position: fixed;
    /* top: 0%; */
    left: 0%;
    width: 100%;
    z-index: 1;
}


.nav-link-inner--text,
.nav-type2 {
    position: relative;
}

.nav-type2 :after {
    content: "";
    background-color: black;
    position: absolute;
    height: 3px;
    width: 0%;
    left: 0;
    bottom: -10px;
    transition: .3s;
}

.nav-link-inner--text:after {
    content: "";
    background-color: white;
    position: absolute;
    height: 3px;
    width: 0%;
    left: 0;
    bottom: -10px;
    transition: .3s;
}

.nav-link-inner--text:hover,
.nav-type2:hover {
    width: 100%;
}

.nav-link-inner--text:hover:after,
.nav-type2:hover:after {
    width: 100%;
}


/* Navbar ends here............... */


/* sidebar starts here........ */

.toggle-btn span {
    color: white;
    font-size: 40px;
}

.nav-tab {
    width: 400px;
    /* width: 350px; */
    /* transition: .5s; */
    background-color: #212121;
    /* background-color: #ed03be; */
    /* background-color: black; */
    color: white;
}


.sidebar {
    height: 100%;
    min-height: 100vh;
    /* width: 20%; */
    /* width: 350px; */
    background-color: #ed03be;
    /* background-color: black; */
    position: relative;
    transition: .5s;
    z-index: 1;
}

.main-container-siderbar {
    position: relative;
    transform: translateX(-1%);
    /* left: 20%; */
    /* width: 80%; */
    overflow-y: auto;
    max-height: 100vh;
    z-index: 1;
}


.sidebar-list {
    position: absolute;
    /* top: 5%; */
    left: 10%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    flex-direction: column;
    color: white;
}

.sidebar-list-row {
    list-style: none;
    margin-bottom: 15px;
}

.sidebar-icon {
    margin-right: 20px;
}


.showSideBar {
    height: 236vh;
    width: 20%;
    /* width: 350px; */
    background-color: #ed03be;
    /* background-color: black; */
    position: relative;
    transition: .5s;
    transform: translateX(0);
}

@media (max-width: 568px) {
    .sidebar {
        transform: translateX(-170%);
        /* opacity: 0; */
    }

    .showSideBar {
        width: 300px;
        z-index: 1;
    }

    .nav-tab {
        width: 100%;
    }

    .main-container-siderbar {
        position: relative;
        transform: translateX(-100px);
        z-index: -1;
        width: 100vw;
    }
}


/* Hero Section starts here.............. */
.carousel-hero-section {
    height: 90vh;
}

@media (max-width: 568px) {
    .carousel-hero-section {
        height: 120vh;
    }
}

.service-card {
    height: 300px;
    width: 400px;
}

.service-card-icon {
    font-size: 50px;
}

/* blog section starts here............. */
.blog-section {
    min-height: 100vh;
    /* overflow-y: auto; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-container {
    width: 80vw;
}

.blog-field {
    border-color: rgb(188, 187, 187);
}

.blog-card {
    height: 520px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
}

.blog-card-content {
    height: 100%;
    width: 100%;
    border-bottom: 2px solid rgb(174, 172, 172);
}

.blog-card-content .img-container {
    height: 250px;
    border-bottom-right-radius: 100px;
}

.blog-card-content .img-container img {
    z-index: 1;
}

.add-blog-container {
    width: 100vw;
    height: 100%
}

.add-blog-card {
    height: 100%;
}

.add-blog-card h5 {
    font-size: 40px;
}

.theme-container {
    margin-top: 150px;
}

@media (max-width: 568px) {
    .blog-card-content .img-container {
        height: 150px;
    }

    .blog-card-content {
        height: 90%;
    }

    .add-blog-card h5 {
        font-size: 20px;
    }

    .blog-container-login {
        width: 80vw;
    }

    .blog-container {
        margin-top: 40px;
        width: 90vw;
    }

    .responsive-title {
        font-size: 20px;
    }

    .theme-container {
        margin-top: 50px;
    }
}

/* blog section ends here................ */


.employee-img-container {
    height: 150px;
    width: 150px;
    border-radius: 75px;
}


.employee-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.date-field-style {
    border: none;
    padding-top: 6px;
    width: 365px;
    padding-bottom: 8px;
    color: grey
}

.input-group-alternative ::placeholder {
    color: rgb(129, 127, 127);
}

.query-overlay {
    position: absolute;
    height: 0;
    width: 100%;
    z-index: 3;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 66, 0.8);
    animation: expandOverlay 1s ease-in-out forwards;
}

@keyframes expandOverlay {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}


.custom-btn {
    background-color: transparent;
    border: 2px solid white;
    width: 120px;
    color: white;
    position: relative;
    transition: 0.4s all ease 0s;
}

.custom-btn:hover {
    color: white;
    border: none;
}

.custom-btn:hover {
    color: white;
    border: none;
}

.custom-btn::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    /* background-color: white; */
    background-color: #ed03be;
    z-index: -1;
    transition: 0.4s all ease 0s;
    color: white;
}

.signup-btn::before {
    left: unset;
    right: 0;
    width: 0;
}

.signup-btn:hover::before {
    right: unset;
    left: 0;
    width: 100%;
}

.login-area-overlay .closeBtn {
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.dropdown-item {
    color: white;
    /* background-color: #ed03be; */
}

.dropdown-item:hover {
    color: #ed03be;
    background-color: transparent;
}

.dropdownItem2:hover {
    font-weight: bold;
}


.footer {
    background-color: rgba(0, 0, 66, 1);
    color: white;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 568px) {
    .footer {
        height: 400px;
    }

    .banner-content h1 {
        font-size: 30px;
    }
}


.login3-container {
    margin: 0;
    padding: 0;
    height: 60vh;
    overflow: hidden;
}

.login3-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    padding-bottom: 60px;
}

.login3-wrapper h1 {
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid silver;
}

.login3-wrapper .login3_form {
    padding: 0 40px;
    box-sizing: border-box;
}

.login3_form .login3_txt_field {
    position: relative;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
}

.login3_txt_field input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
}

.login3_txt_field label {
    position: absolute;
    top: 50%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: .5s;
}

.login3_txt_field span::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #2691d9;
    transition: .5s;
}

.login3_txt_field input:focus~label,
.login3_txt_field input:valid~label {
    top: -5px;
    color: #2691d9;
}

.login3_txt_field input:focus~span::before,
.login3_txt_field input:valid~span::before {
    width: 100%;
}

.login3_pass {
    margin: -5px 0 20px 5px;
    color: #a6a6a6;
    cursor: pointer;
}

.login3_pass:hover {
    text-decoration: underline;
}

.login3-wrapper .login-btn {
    width: 100%;
    height: 50px;
    border: 1px solid;
    background: #2691d9;
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
}

.login3-wrapper input[type="submit"]:hover {
    border-color: #2691d9;
    transition: .5s;
}

/* .signup_link{
    margin: 30px 0;
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
  .signup_link a{
    color: #2691d9;
    text-decoration: none;
  }
  .signup_link a:hover{
    text-decoration: underline;
  } */

.blog-carousel {
    height: 400px;
    width: 300px;
    position: relative;
    overflow: hidden;
}

.blog-carousel-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: end;
}

.blog-carousel img {
    transition: .5s;
    /* Increase the scale value as desired */
}

.blog-carousel:hover img {
    transform: scale(1.2);
    /* Increase the scale value as desired */
}

.blog-card .badge {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
}


.blog-horizontal-card {
    margin-bottom: 150px;
    position: relative;
}

.blog-horizontal-content {
    flex: 3;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.blog-horizontal-content h1 {
    font-size: 48px;
}

.blog-img {
    min-height: 300px;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
}

.blog-img-carousel {
    min-height: 300px;
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 7px;
}

.blog-img-overlay {
    width: 100%;
    height: 100%;
    /* background-color: rgb(149, 208, 242); */
    /* background-color: #ed03be; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.edit-div {
    position: absolute;
    bottom: 0;
    right: 0;
}

/* display flex custom classes */
.center1 {
    display: flex;
    justify-content: center;
}

.sideByside {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.start {
    display: flex;
    justify-content: start;
    align-items: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.end {
    display: flex;
    justify-content: end;
    align-items: center;
}

/* display flex ends here.................. */

.black {
    background-color: black;
}

/* text css starts here............. */

.textBold {
    font-weight: bolder;
    color: black;
}

.whiteBold {
    font-weight: bolder;
    color: white;
}

.themeBold {
    font-weight: bolder;
    color: #ed03be;
}

.letterSpacing {
    letter-spacing: 3px;
}

/* text css ends here............... */

.onTop-btn {
    height: 70px;
    width: 70px;
    border-radius: 50px;
    border: none;
    background-color: #ed03be;
    /* background-color: rgb(91, 109, 228); */
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.cursor {
    cursor: pointer !important;
}

.onTop-btn:hover {
    background-color: rgb(91, 109, 228);
    color: white;
    font-size: 20px;
    border: none;
}

.onTop-btn-container {
    position: fixed;
    height: 70px;
    width: 70px;
    border-radius: 50px;
    left: 95%;
    top: 90%;
    z-index: 1;
}

@media (max-width: 568px) {
    .onTop-btn {
        height: 50px;
        width: 50px;
        border-radius: 25px;
        font-size: 15px;
    }

    .onTop-btn-container {
        left: 90%;
    }
}


.custom-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: grid;
    place-items: center;
}

.custom-icon2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    display: grid;
    place-items: center;
}

.imgStyle {
    height: 100%;
    width: 100%;
}

.label {
    background-color: rgba(103, 103, 225, 0.1);
    color: rgba(103, 103, 225, 1);
    width: 20%;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
}

@media (max-width: 568px) {
    .label {
        width: 40%;
        margin-top: 20px;
    }
}

.centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hrtagline {
    width: 25%;
    background-color: rgb(85, 85, 223);
    height: 3px;
}

.equal-height-col {
    display: flex;
    flex-wrap: wrap;
}

.sectionHeight {
    margin-top: 100px;
}


/* Navbar stars here............... */

.sticky {
    position: fixed;
    /* top: 0%; */
    left: 0%;
    width: 100%;
    z-index: 1;
}


.nav-link-inner--text,
.nav-type2 {
    position: relative;
}

.nav-type2 :after {
    content: "";
    background-color: black;
    position: absolute;
    height: 3px;
    width: 0%;
    left: 0;
    bottom: -10px;
    transition: .3s;
}

.nav-link-inner--text:after {
    content: "";
    background-color: white;
    position: absolute;
    height: 3px;
    width: 0%;
    left: 0;
    bottom: -10px;
    transition: .3s;
}

.nav-link-inner--text:hover,
.nav-type2:hover {
    width: 100%;
}

.nav-link-inner--text:hover:after,
.nav-type2:hover:after {
    width: 100%;
}


/* Navbar ends here............... */


/* sidebar starts here........ */
.toggle-btn {
    background-color: #212121;
    border: none;
}

.toggle-btn:focus {
    background-color: #212121;
    border: none;
}

.toggle-btn span {
    color: white;
    font-size: 20px;
}


.sidebar {
    height: 100%;
    min-height: 100vh;
    /* width: 20%; */
    /* width: 350px; */
    background-color: #212121;
    /* background-color: #5e72e4; */
    /* background-color: #866ec7; */
    /* background-color: black; */
    position: relative;
    transition: .5s;
    z-index: 1;
}

/* .main-container-siderbar {
    position: relative;
    transform: translateX(-1%);
    z-index: 1;
} */


.sidebar-list {
    position: absolute;
    /* top: 5%; */
    left: 10%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    flex-direction: column;
    color: white;
}

.sidebar-list-row {
    list-style: none;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(156, 153, 153);
}
.sidebar-list-row p:hover{
    font-weight: bold;
}

.sidebar-icon {
    margin-right: 20px;
}


.showSideBar {
    height: 236vh;
    width: 20%;
    /* width: 350px; */
    background-color: #212121;
    position: relative;
    transition: .5s;
    transform: translateX(0);
}

@media (max-width: 568px) {
    .sidebar {
        transform: translateX(-170%);
        /* opacity: 0; */
    }

    .showSideBar {
        width: 300px;
        z-index: 1;
    }

    .nav-tab {
        width: 100%;
    }

    .main-container-siderbar {
        position: relative;
        transform: translateX(-100px);
        z-index: -1;
        width: 100vw;
    }
}


/* Hero Section starts here.............. */
.carousel-hero-section {
    height: 90vh;
}

@media (max-width: 568px) {
    .carousel-hero-section {
        height: 120vh;
    }
}

.service-card {
    height: 300px;
    width: 400px;
}

.service-card-icon {
    font-size: 50px;
}


/* user list table section starts here............... */

.user-table th {
    padding: 15px 0;
    text-align: center;
}

.user-table-col td {
    font-weight: bold;
    text-align: center;
    line-height: 40px;
}

.table-section {
    width: 1000px;
    background-color: white;
}

.table-section Button {
    width: 120px;
}

@media (max-width: 568px) {
    .table-section {
        width: 400px;
        z-index: 1;
    }
}

/* user list table section ends here...................... */

.employee-img-container {
    height: 150px;
    width: 150px;
    border-radius: 75px;
}

.employee-img-container img {
    border-radius: 75px;
    /* border: 7px solid rgb(85, 85, 223); */
}

.employee-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.date-field-style {
    border: none;
    padding-top: 6px;
    width: 365px;
    padding-bottom: 8px;
    color: grey
}

.input-group-alternative ::placeholder {
    color: rgb(129, 127, 127);
}

.banner-area {
    height: 100vh;
    width: 100%;
    background-image: url("./assets/img/banner3.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}





.login-area-overlay {
    position: absolute;
    height: auto;
    width: 100%;
    z-index: 4;
    left: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(0, 128, 128, 0.2), rgba(0, 128, 128, 0.8));
    animation: expandOverlay 1s ease-in-out forwards;
}

.query-overlay {
    position: absolute;
    height: 0;
    width: 100%;
    z-index: 3;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 66, 0.8);
    animation: expandOverlay 1s ease-in-out forwards;
}

@keyframes expandOverlay {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}



.banner-area-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
}

.banner-content h1 {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.15em;
    margin-bottom: 20px;
}

@media (max-width: 568px) {
    #root{
        overflow: hidden;
        padding:0;
        margin:0;
        width: 100%;
    }
}

.custom-btn {
    background-color: transparent;
    border: 2px solid white;
    width: 120px;
    color: white;
    position: relative;
    transition: 0.4s all ease 0s;
}

.custom-btn:focus {
    outline: none;
}

.custom-btn:hover {
    color: white;
    border: none;
}

.custom-btn::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    /* background-color: white; */
    background-color: #ed03be;
    z-index: -1;
    transition: 0.4s all ease 0s;
}

.signup-btn::before {
    left: unset;
    right: 0;
    width: 0;
}

.signup-btn:hover::before {
    right: unset;
    left: 0;
    width: 100%;
}

.closeBtn {
    font-weight: bold;
    color: white;
    cursor: pointer;
    z-index: 5;
}

.dropdown-item {
    color: white;
    /* background-color: #ed03be; */
}

.dropdown-item:hover {
    color: #ed03be;
    background-color: transparent;
}

.dropdownItem2:hover {
    font-weight: bold;
}


.footer {
    background-color: rgba(0, 0, 66, 1);
    color: white;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 568px) {
    .footer {
        height: 400px;
    }
    .banner-content h1 {
        font-size: 25px;
    }
}


.login3-container {
    margin: 0;
    padding: 0;
    height: 60vh;
    overflow: hidden;
}

.login3-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    padding-bottom: 70px;
}

.login3-wrapper h1 {
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid silver;
}

.login3-wrapper .login3_form {
    padding: 0 40px;
    box-sizing: border-box;
}

.login3_form .login3_txt_field {
    position: relative;
    /* border-bottom: 2px solid #adadad; */
    margin: 30px 0;
}

.login3_txt_field input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
}

.login3_txt_field label {
    position: absolute;
    top: 50%;
    left: 5px;
    /* color: #adadad; */
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: .5s;
}

.login3_txt_field span::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #ed03be;
    /* background: #2691d9; */
    transition: .5s;
}

.login3_txt_field input:focus~label,
.login3_txt_field input:valid~label {
    top: -5px;
    color: #ed03be;
    /* color: #2691d9; */
}

.login3_txt_field input:focus~span::before,
.login3_txt_field input:valid~span::before {
    width: 100%;
}

.login3_pass {
    margin: -5px 0 20px 5px;
    color: #a6a6a6;
    cursor: pointer;
}

.login3_pass:hover {
    text-decoration: underline;
}

.login3-wrapper .login-btn {
    width: 100%;
    height: 50px;
    border: 1px solid;
    background: #ed03be;
    /* background: #2691d9; */
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
}

/* .login3-wrapper input[type="submit"]:hover {
    border-color: #ed03be;
    transition: .5s;
} */

/* .signup_link{
    margin: 30px 0;
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
  .signup_link a{
    color: #2691d9;
    text-decoration: none;
  }
  .signup_link a:hover{
    text-decoration: underline;
  } */

.blog-carousel {
    height: 400px;
    width: 300px;
    position: relative;
}


.blog-horizontal-card {
    margin-bottom: 150px;
}

.blog-horizontal-content {
    flex: 3;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}

.blog-horizontal-content h1 {
    font-size: 45px;
    color: black;
}

.blog-horizontal-content .blog-category {
    font-size: 18px;
    color: black;
    font-weight: 500;
}

.blog-horizontal-content button {
    border: none;
    cursor: pointer;
    background-color: white;
    /* border: 1px solid #2c95f7;
    color: #2c95f7; */
    border: 1px solid #ed03be;
    color: #ed03be;
}

.blog-horizontal-content button:hover {
    /* background-color: #2c95f7; */
    background-color: #ed03be;
    border: 1px solid white;
    /* color: black; */
    color: white;
}

.blog-horizontal-content button:focus {
    outline: none;
    /* Remove the default focus outline */
}

.blog-img {
    height: 350px;
    width: 100%;
    object-fit: cover;
    position: relative;
    /* z-index: 2; */
}


.blog-horizontal-card .badge {
    position: absolute;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    top: -25px;
    left: 10px;
}



.blog-user {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
}

.blog-user img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.blog-edit-btn {
    background-color: rgb(76, 181, 242);
    color: white;
    height: 25px;
    width: 30px;
    margin-right: 15px;
    padding: 0 6px;
    cursor: pointer;
}

.blog-delete-btn {
    background-color: red;
    color: white;
    height: 25px;
    width: 30px;
    margin-right: 15px;
    padding: 0 4px;
    cursor: pointer;
}

.eachSectionMarginTop {
    margin-top: 7rem !important;
}


.dummy-div {
    height: 250px;
    width: 90%;
    position: relative;
    overflow: hidden;
}

.dummy-div-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dummy-div img {
    transition: .5s;
    /* Increase the scale value as desired */
}

.dummy-div:hover img {
    transform: scale(1.2);
    /* Increase the scale value as desired */
}

.feature-div img{
    transition: .5s;
    cursor: pointer;
}
.feature-div {
    overflow: hidden;
}
.feature-div:hover img {
    transform: scale(1.1);
    /* Increase the scale value as desired */
}

.divider {
    width: 70px;
    height: 6px;
    background: #000;
    display: inline-block;
    position: relative;
    /* -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); */
}

.themeBtn2 {
    border: none;
    cursor: pointer;
    background-color: white;
    /* border: 1px solid #2c95f7;
    color: #2c95f7; */
    border: 1px solid #ed03be;
    color: #ed03be;
}

.themeBtn2:hover {
    /* background-color: #2c95f7; */
    background-color: #ed03be;
    border: 1px solid white;
    /* color: black; */
    color: white;
}

.themeBtn2:focus {
    outline: none;
    /* Remove the default focus outline */
}

.contactlist-container {
    width: 100%;
    overflow-x: auto; 
}